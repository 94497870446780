/* .loader {
    border: 16px solid #180303;
    border-radius: 50%;
    border-top: 10px solid #ef6767;
    border-right: 10px solid #a14848;
    border-bottom: 10px solid #ff52005e;
    border-left: 10px solid #f7a7b5;
    width: 100px;
    height: 100px;
    animation: spin 0.5s linear infinite;
    margin: 0 auto;
    margin-top: 36vh;
}

.spinner-img {
    height: 40px;
    margin-top: 13px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

.spinner {
    width: 72px;
   height: 72px;
    display: grid;
    border: 3.2px solid #0000;
    border-radius: 50%;
    border-color: #2a6a43 #0000;
    animation: spinner-e04l1k 1.4s infinite linear;
    margin: 0 auto;
    margin-top: 36vh;
 }
 
 .spinner::before,
 .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 1.6px;
    border: inherit;
    border-radius: 50%;
 }
 
 .spinner::before {
    border-color: #0b2b11 #0000;
    animation: inherit;
    animation-duration: 0.7s;
    animation-direction: reverse;
 }
 
 .spinner::after {
    margin: 6.4px;
 }
 
 @keyframes spinner-e04l1k {
    100% {
       transform: rotate(1turn);
    }
 }